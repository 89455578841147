import { NotificationManager } from "react-notifications";
/**
 * Creates notification type of message
 * Red error(File upload failed)
 * Yellow warning(Link maybe visible to others)
 * Green Success(EX: file uplaoded success)
 */
const createNotification = (message, type,notfText=null) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case "info":
      NotificationManager.info(message);
      break;
    case "success":
      NotificationManager.success(message);
      break;
    case "warning":
      NotificationManager.warning(message);
      break;
    case "error":
      NotificationManager.error(message);
      break;
  }
  if(notfText)message=notfText
  return {"type":type,"message":message}
};

export default createNotification;
