/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { handleGoogleSignIn } from "../util/firebase-authentication";
import Navigation from "../App/AppChildren/AppContainerChildren/Navigation";
import Tutorial from "../App/AppChildren/AppContainerChildren/Tutorial";
import "../../assets/css/SignIn.css"

export default class SignIn extends Component {
  
  /**
   * 
   * From this file, the option to login, and to sign up has been removed, to add those,
   *  uncomment the divs for login using id pass and the sign up button div as well.
   * This file is basically responsible for signin handling and taking actions after the user signs in
   * This file is main root for the project or application
   */

  constructor(props) {
    super(props);
    this.state = {
      showTut: false 
    }
  }

  appInfo = [
    {
      "title" : "Powerful Search & Analysis",
      "data" : <>
      <div> Find specific diamonds with natural language search or filter by cut, color, clarity, price, and more.</div>
      <div> Gain valuable insights with interactive visualizations of price trends and discounts. Compare multiple diamonds side-by-side to make informed decisions.</div>
      </>
    },
    {
      "title" : "Diamond Details & Discovery",
      "data" : <>
      <div> Easily access diamond certificates, videos, and comprehensive details like cut, color, clarity, carat weight, and price.</div>
      <div> Find the perfect diamond with search by specific properties or discover similar diamonds based on a reference stone.</div>
      </>
    },
    {
      "title" : "Share Your Diamond Insights",
      "data" : <>
      <div> Generate sharable links or send results directly via email, keeping everyone on the same page.</div>
      <div> Before sharing, you can adjust diamond prices (if needed) and choose how the information appears. </div>
      </>
    },
    {
      "title" : null,
      "data" : null
    }
  ]

  setShowTut = (newVal) => {
    this.setState({showTut: newVal})
  }


  

  renderAppInfo = () => {
    let appInfo = []
    this.appInfo.forEach((info)=>{
      if(info.data && info.title)
      {
        let infoComp = <div className="info">
          <div className="infoTitle">
            {info.title}
          </div>
          <div className="infoData">
            {info.data}
          </div>
        </div>
        appInfo.push(infoComp)
      }
    })
    return <div className="infoContainer">{appInfo}</div>
  }
  /**
   * The render function which is responsible for returning the frontend with the react properties
   * This will return one properties at time time so we have to merge all in one react component
   */
  render = () => {
    return (
      <>
        <Navigation loggedIn={false} setShowTut={this.setShowTut} handleGoogleSignIn={handleGoogleSignIn} />
        {(this.state.showTut)?(<Tutorial setShowTut={this.setShowTut} />):(<></>)}
        {this.renderAppInfo()}
      </>
    );
  };
}
