import React from "react";
import "../../assets/css/LoadingSpinner.css";

/**
 * For creatinfg the loading spinner
 * Used when loading the messages
 * When refershing the app
 */
const LoadingSpinner = () => {
  return (
    <div className="loadingio-spinner-rolling-e18jpcmoyn">
      <div className="ldio-rw1usax06l">
        <div></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
