import React from "react";
import { MDBDataTableV5 } from "mdbreact";
/**
 * Creates the dynamic table which is used in displaying the results
 * It is an react library where we also get the search options and the sorting options
 * we wrap it in a react tag, so that it can be used very easily and also we have flexibility with the react
 */
export default class DataTable extends React.Component {

  modify(data) {
    let cols = data.columns.map(x => x.label)
    let rows = data.rows
    let linkHeaders = {
      "cert": "certificate",
      "lab": "certificate",
      "vid": "view",
      "view": "view"
    }

    for (let col of cols) {
      for (let linkHeader in linkHeaders) {
        if (col.toLowerCase().includes(linkHeader)) {
          linkHeaders[col] = linkHeaders[linkHeader]
          break
        }
      }
    }

    let newdata = rows.map(row => {
      let reportNumber = row["Report no"]
      if ("Generated report no" in row) reportNumber = row["Generated report no"]
      for (let col of cols) {
        let val = row[col]
        if (col.toLowerCase().includes("report")) {
          row[col] = <span className="reportVal" title="Paste to Clipboard" value={`${row[col]}`} onClick={this.props.appendQuery}>{row[col]}</span>
        }
        else if (Number.isNaN(val)) {
          row[col] = <span>-</span>
        }
        else if (typeof val === 'string') {
          if (val.toLowerCase()=="nan") {
            row[col] = <span>-</span>
          }
          else if (val.startsWith('http')) {
            let linkLabel = col.split(" ")[0]
            if (linkHeaders[col]) {
              row[col] = <span className="text-primary cursor-pointer" value={`${linkHeaders[col]} ${reportNumber}`} onClick={this.props.handleQuerySelect}>{linkLabel}</span>
            }
            else {

              row[col] = <a className="text-primary" href={val} target="_blank">{linkLabel}</a>
            }
          }
          else {

            row[col] = val.toUpperCase()
          }
        }

      }
      return row
    })
    return data
  }
  render = () => {

    let modifiedData = this.modify(this.props.data)
    modifiedData.columns = this.props.data.columns.map((col) => {
      col.sortIcon = 'right'
      return col;
    })
    return (
      <MDBDataTableV5
        small
        searchTop
        bordered
        striped
        responsive
        searchBottom={false}
        data={modifiedData}
        paginationLabel={['<', '>']}
      />
    );
  }
}
