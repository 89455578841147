import { combineReducers } from "redux";

/**
 * These are the reducers which are responsible for taking actions based on the type and return the state
 * The auth state will be responsible for authentication
 * The postmessage is responsible for loading the messages
 * Similarly vendor, inventory, master, and summary are for the same things
 */
const AuthStateReducer = (currentState = null, action) => {
  if (action.type === "CHANGE_AUTH_STATE") {
    return action.payload;
  }
  return currentState;
};

const messagesReducer = (messages = [], action) => {
  // console.log(action);

  let newMessages = null

  if (action.type === "POST_MESSAGE") {
    const index = messages.findIndex(
      (message) => message.key === action.payload.key
    );
    if (index === -1) newMessages =  [...messages, action.payload];
    else newMessages =  [
      ...messages.slice(0, index),
      action.payload,
      ...messages.slice(index + 1),
    ];
  }
  else if(action.type === "DELETE_MESSAGE")
  {
    const index = messages.findIndex(
        (message) => message.key === action.payload.key
      );
      if (index === -1) newMessages =  [...messages];
      else newMessages =  [
        ...messages.slice(0, index),
        ...messages.slice(index + 1)
      ]
  }
  else if(action.type === 'CLEAR_MESSAGE')
  {
    newMessages =  [];
  }
  else newMessages = messages
  // console.log(newMessages);
  return newMessages;
};

const vendorsReducer = (vendors = [], action) => {
  if (action.type === "ADD_VENDOR") {
    return [...vendors, action.payload];
  }
  return vendors;
};

const inventoryFilesReducer = (inventoryFiles = [], action) => {
  if (action.type === "ADD_INVENTORY_FILES") {
    return [...inventoryFiles, ...action.payload];
  }

  return inventoryFiles;
};

const masterFilesReducer = (masterFiles = [], action) => {
  if (action.type === "ADD_MASTER_FILES") {
    return [...masterFiles, ...action.payload];
  }

  return masterFiles;
};

const summaryFilesReducer = (summaryFiles = [], action) => {
  if (action.type === "ADD_SUMMARY_FILES") {
    return [...summaryFiles, ...action.payload];
  }

  return summaryFiles;
};

export default combineReducers({
  user: AuthStateReducer,
  messages: messagesReducer,
  vendors: vendorsReducer,
  inventoryFiles: inventoryFilesReducer,
  masterFiles: masterFilesReducer,
  summaryFiles: summaryFilesReducer,
});
