/**
 * Handling the values for numeric types with their keyword
 * Ex: ratio 1.0 2.0 and more
 */
export const rangeHandler = (c, a, b) => {
  if (a != null && a.length === 0 && b != null && b.length === 0) return "";
  else if (a != null && a.length === 0) {
    return c + " " + b + " " + b + " ";
  } else if (b != null && b.length === 0) {
    return c + " " + a + " " + a + " ";
  }
  return c + " " + a + " " + b + " ";
};
/**
 * For converting the data to query
 * Takes all inputs, processes for  null values
 * and returns the query according to some order
 */
export const queryGenerator = (
  data,
  checkSubmit,
  minPrice,
  maxPrice,
  minWeight,
  maxWeight,
  minM1,
  maxM1,
  minM2,
  maxM2,
  minM3,
  maxM3,
  minTotal,
  maxTotal,
  minRatio,
  maxRatio,
  minDepth,
  maxDepth
) => {
  // console.log(data);
  var price = ""
  if(!Number.isNaN(minPrice) &&  !Number.isNaN(maxPrice))
  {
    price = "total "+ minPrice.toString()+" "+ maxPrice.toString()+" "
  }
  var weight = ""
  if(!Number.isNaN(minWeight) &&  !Number.isNaN(maxWeight))
  {
    weight = minWeight.toString()+" "+ maxWeight.toString()+" "
  }


  // if (minWeight === 0)
  //   minWeight = 0.0001
  // if (minWeight > 10 || minWeight <= 0) minWeight = "";
  // if (maxWeight > 10 || maxWeight <= 0) maxWeight = "";
  // const weight = rangeHandler("", minWeight, maxWeight);
  const m1 = rangeHandler("MES1", minM1, maxM1);
  const m2 = rangeHandler("MES2", minM2, maxM2);
  const m3 = rangeHandler("MES3", minM3, maxM3);
  const total = rangeHandler("TOTAL", minTotal, maxTotal);
  const depth = rangeHandler("DEPTHPER", minDepth, maxDepth);
  const ratio = rangeHandler("RATIO", minRatio, maxRatio);
  // console.log("weight",weight);
  // console.log("m1",m1);
  // console.log("m2",m2);
  // console.log("m3",m3);
  // console.log("total",total);
  // console.log("depth",depth);
  // console.log("ratio",ratio);
  return (
    data["D"] +
    data["E"] +
    data["F"] +
    data["G"] +
    data["H"] +
    data["I"] +
    data["J"] +
    data["K"] +
    data["L"] +
    data["M"] +
    data["N"] +
    data["O"] +
    data["P"] +
    data["none"] +
    data["faint"] +
    data["medium"] +
    data["strong"] +
    data["verystrong"] +
    data["round"] +
    data["marquise"] +
    data["princess"] +
    data["oval"] +
    data["pear"] +
    data["heart"] +
    data["cushionmod"] +
    data["ashcher"] +
    data["cushion"] +
    data["radiant"] +
    price +
    weight +
    m1 +
    m2 +
    m3 +
    total +
    ratio +
    depth +
    data["fl"] +
    data["if"] +
    data["vvs1"] +
    data["vvs2"] +
    data["si1"] +
    data["si2"] +
    data["i1"] +
    data["i2"] +
    data["i3"] +
    data["vs1"] +
    data["vs2"] +
    data["gia"] +
    data["hrd"] +
    data["fm"] +
    data["igi"] +
    data["multivalue"]
  );
};
