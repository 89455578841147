import React from "react";
import { connect } from "react-redux";

import MessageDetail from "./MessageListChildren/MessageDetail";
import "../../../../../../src/assets/css/MessageList.css";
import CancelIcon from '@mui/icons-material/Cancel';
import { BarChart } from "@mui/x-charts";


class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  componentDidMount = () => {
    if(!this.props.freezeScroll)
    {
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    }
    
  };

  componentDidUpdate = () => {
    if(!this.props.freezeScroll)
    {
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    }
  };
  /**
   * Basically here all the messages are rendered with their firebase key value pairs
   * it takes the messages from the postMessage actions
   * To learn more about the react redux click below
   * https://react-redux.js.org/
   * https://www.youtube.com/watch?v=CVpUuw9XSjY
   * These are some links for redux
   * So now for every message you have a single message with its detail and the key value pairs
   * It is under the MessageDetail tag
   */

  removeWaitMessages = (currMessages) => {
    let newMessages = []
    currMessages.forEach((msg, ind) => {
      if (ind == currMessages.length - 1 || !(msg.text && msg.text.startsWith("Please wait"))) newMessages.push(msg)
    });
    return newMessages
  }

  handleClearMessage = (currMessages) => {
    let clearIdx = currMessages.slice().reverse().findIndex(msg => msg.queryMode == "clear")
    if (clearIdx != -1) {
      let waitTime = 0
      clearIdx = currMessages.length - clearIdx - 1
      currMessages = currMessages.slice(clearIdx + 1)

    }
    return currMessages
  }

  sortTimeStamp = (currMessages) => {

    let sortLogic = (l,r) =>{
      let l_time = l.timeStamp
      let r_time = r.timeStamp
      return l_time - r_time
    }
    currMessages = currMessages.sort(sortLogic)
    return currMessages
  }


  applyMessageOperation = () => {
    let currMessages = this.props.messages
    try {
      currMessages = this.handleClearMessage(currMessages)
      currMessages = this.removeWaitMessages(currMessages)
      currMessages = this.sortTimeStamp(currMessages)
      return currMessages

    } catch (error) {
      return currMessages
    }
  }


  renderMessages = () => {
    let messages = []
    messages = this.applyMessageOperation()


    let MessageDetailList = messages.map((message) => {
      return <MessageDetail setFreezeScroll={this.props.setFreezeScroll} columnPreference={this.props.columnPreference} appendQuery={this.props.appendQuery} checked={this.props.currResponseId === message.key} docID={message.key} setResponse={this.props.setResponse} setPriceSeries={this.props.setPriceSeries} {...message} fileType={this.props.fileType} />;
    });


    return MessageDetailList


  };

  /**
   * Very intuitive name
   * Scroll the screen to the bottom whenever a new nmessage is arrived
   */
  scrollToBottom = () => {
    if(!(document.activeElement==document.getElementById("queryTextInput")) && this.messagesEnd)
    {
      this.messagesEnd.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
        lignToTop: false,
      });
    }
  };

  render() {
    return (
      <>
      <div
        className="container-fluid div1"
      >
        
        <div className="ui list divided">
          {this.renderMessages()}

          <div
            className="dummy-div div2"
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  if (!state.user) return {};

  return {
    userID: state.user.uid,
    messages: state.messages,
  };
};

export default connect(mapStateToProps, { postMessage })(MessageList);
