import React from "react";
import "../../../../../../src/assets/css/ButtonCss.css";
/**
 * The button for submitting query
 * Initially it was in the messenger.js file
 * Now we have created a component for the button itself
 *
 */
export class SubmitQuery extends React.Component {
  render() {
    return (
      <button 
      // className="btn btn-default btn-md button" 
      className="btn button-easy-query"
      disabled={!this.props.isAllowed}
      type="submit">
        Send Query
        <div className="button__horizontal"></div>
	    <div className="button__vertical"></div>
      </button>
    );
  }
}
