/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../../../util/firebase-authentication";
import { changeAuthState } from "../../../../actions";
import "../../../../assets/css/Navigation.css";
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DefaultUserImage from "../../../../assets/images/profile_placeholder.png";
import image1 from "../../../../assets/images/LOGO2.png"
import Divider from "./divider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from '@mui/material/Tooltip';
import googleLogo from "../../../../assets/images/google.png"
/**
 * The class declaration for the navbar
 */
class Navigation extends Component {
  curr_page = "Home"

  //Func for signing out, sets the user info to null and redirects to the login page
  onSignOut = () => {
    window.location.pathname = "/"
    sessionStorage.removeItem("showTut")
    sessionStorage.removeItem("pageType")
    auth
      .signOut()
      .then((response) => {
        this.props.changeAuthState(null);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  /**
   * The user image for the particular url with a image tag
   */
  renderUserImage = (imageUrl) => {
    return <img src={imageUrl} className="user-image" />;
  };

  showTut = () => {
    // console.log(this.curr_page);
    sessionStorage.setItem("showTut", "true");
    sessionStorage.setItem("pageType", this.curr_page);
    this.props.setShowTut(false);
    this.props.setShowTut(true);
  }
  /**
   *
   * @returns The navbar component
   * initially had analysis and inventory too
   * but they are currently disabled
   */
  render = () => {
    let isMobileScreen = (window.innerWidth < 1000)


    if (window.location.pathname === "/") this.curr_page = "Home"
    else if (window.location.pathname === "/analysis") this.curr_page = "Analysis"
    else if (window.location.pathname === "/uploadFiles") this.curr_page = "Upload Inventory Data"
    let curr_page = this.curr_page
    return (
      <>
        <Divider />
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          id="navigation"
          style={{ padding: "0" }}
        >
          <div className="container">
            <Navbar.Brand href="https://d360.tech/"><img src={image1} alt="V360" style={{ height: "40px" }} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Tooltip arrow title="Go to Home Page">
                <Nav className="mr-auto">
                  {(!isMobileScreen)?(<>
                    <span className="pathSymbol">
                    &gt;
                  </span>
                  </>):(<></>)}
                  
                  <Nav.Link>
                    <Link onClick={e => { window.location.href = "/" }} style={{ fontSize: "20px" }}>Diamond Assist</Link>
                  </Nav.Link>
                </Nav>
              </Tooltip>
              {(this.props.loggedIn) ? (<>
                <Tooltip arrow title="Change Current page from given dropdown" placement="right">
                  <Nav className="align-middle">
                  {(!isMobileScreen)?(<>
                    <span className="pathSymbol">
                    &gt;
                  </span>
                  </>):(<></>)}
                    <NavDropdown
                      title={
                        <>

                          <span>{curr_page}</span>

                        </>
                      }
                    >
                      {(curr_page != "Home") ?
                        (
                          <NavDropdown.Item onClick={e => { window.location.href = "/" }}>
                            Home
                          </NavDropdown.Item>

                        ) : (<></>)}
                      {(curr_page != "Upload Inventory Data") ?
                        (
                          <NavDropdown.Item onClick={e => { window.location.href = "/uploadFiles" }}>
                            Upload Inventory Data
                          </NavDropdown.Item>

                        ) : (<></>)}
                      {/* {(curr_page != "User Files") ?
                      (
                        <NavDropdown.Item onClick={e => { window.location.href = "/manageUserFiles" }}>
                          Manage User Files
                        </NavDropdown.Item>

                      ) : (<></>)} */}
                      {/* {(curr_page != "Vendor Files") ?
                      (
                        <NavDropdown.Item onClick={e => { window.location.href = "/manageVendorFiles" }}>
                          Manage Vendor Files
                        </NavDropdown.Item>

                      ) : (<></>)} */}
                      {/* {(curr_page != "Analysis") ?
                      (
                        <NavDropdown.Item onClick={e => { window.location.href = "/analysis" }}>
                          Analysis
                        </NavDropdown.Item>

                      ) : (<></>)} */}


                    </NavDropdown>
                  </Nav>
                </Tooltip>
                {(curr_page != "Messenger") ?
                  (
                    <Tooltip arrow title="Check Previous Notifications from here">
                      <Nav className="ms-auto align-middle">
                        <NavDropdown title={
                          <>
                            <span>{(this.props.notfList.length) ? <NotificationsActiveIcon /> : <NotificationsIcon />} </span>
                          </>
                        }>
                          {(this.props.notfList.length) ?
                            (

                              this.props.notfList.map(({ message }) => {
                                return <NavDropdown.Item >{message}</NavDropdown.Item>
                              })

                            ) :
                            (
                              <NavDropdown.Item >No New Notifications</NavDropdown.Item>
                            )}

                        </NavDropdown>
                      </Nav>
                    </Tooltip>

                  ) : (<>
                    <Nav className="ms-auto align-middle">

                    </Nav>
                  </>)}
                <Tooltip arrow title="If you are unable to understand any function, use this to view help window">
                  <Nav id="tutButton" className="align-middle" onClick={this.showTut}>
                    {/* <span><FontAwesomeIcon icon={faInfoCircle} /></span> */}
                    <span>Help</span>
                  </Nav>
                </Tooltip>

                <Tooltip arrow title="Manage your profile related activities from here" placement="right">
                  <Nav className="align-right">
                    <NavDropdown
                      title={
                        <>
                          <span>
                            {" "}
                            {this.renderUserImage(
                              auth.currentUser.photoURL
                            )}{" "}
                            {auth.currentUser.displayName}
                          </span>
                        </>
                      }
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item onClick={this.onSignOut}>
                        Log out
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Tooltip>
              </>) : (<>
                <Nav className="ms-auto align-middle">

                </Nav>
                <Nav className="ms-auto align-middle">

                </Nav>
                <Tooltip arrow title="If you are unable to understand any function, use this to view help window">
                  <Nav id="tutButton" className="align-middle" onClick={this.showTut}>
                    {/* <span><FontAwesomeIcon icon={faInfoCircle} /></span> */}
                    <span>Help</span>
                  </Nav>
                </Tooltip>
                <div className="signInButton" onClick={this.props.handleGoogleSignIn}>
                  <img src={googleLogo} alt="google" />
                  <p>Sign In With Google</p>
                </div>
                
              </>)}



            </Navbar.Collapse>
          </div>
        </Navbar>
      </>
    );
  };
}

const mapStateToProps = (state) => {
  if (!state.user) return {};
  return {
    displayName: state.user.displayName,
  };
};

export default connect(mapStateToProps, { changeAuthState })(Navigation);
