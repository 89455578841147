import React from "react";
import axios from "axios";
import { validateUrl } from "../../../../../../../utils/DiamondFrameUtils";

export class DiamondFrame extends React.Component {

  renderImageFrame(url=this.props.srclink)
  {
    if(url.toLowerCase().includes("storage.googleapis.com"))
    {
      return (<></>)
    }
    else if(url.endsWith("jpg") || url.endsWith("png"))
    {
      return (
        <div className="diamondFrame">
          <img src={url}></img>
        </div>
      )
    }
    else
    {
      return (
        <iframe
          src={url}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          className="diamondFrame"
        ></iframe>
      );
    }
  }

  render() {
    //If the url is not valid, we return that the link is not valid along with the link provided
    if (!validateUrl(this.props.srclink))
      return <p> The link is not valid : {this.props.srclink}</p>;
    let flag = 0;
    //Once we find that the url is valid, we check status code, should be 200
    // axios.get(this.props.srclink,{
    //   headers: {
    //     'Access-Control-Allow-Origin': '*'
    //   }
    // }).then((res) => {
    //   if (res.status !== 200) flag = 1;
    // });
    //If we find that the statusCode is not 200, we assume that the link is not responding
    if (flag)
      return (
        <p>
          url not found for the: <a href={this.props.srclink}>Link</a>
        </p>
      );
    //When everything is fine, return the url in an iframe
    return (<>
    <span>Link : <a target='_blank' href={this.props.srclink}>Open in new tab</a></span>
    {this.renderImageFrame()}
    </>)
  }
}
